// @flow

import React from 'react';
//import { graphql } from 'gatsby';
import { observer, inject } from 'mobx-react';
//import { Provider } from 'mobx-react';
//import * as classNames from 'classnames';

//import styles from './stores.module.scss';
//import Metadata from '../components/Metadata';
import UiStore from '../stores/ui-store';
import JsonStore from '../stores/json-store';
import { STORE_UI, STORE_JSON } from '../constants/stores';
//import {JSON_STORES, JSON_GATE} from '../constants/json';
//import parseLongText from '../utils/parse-long-text'
import { toggleLock } from '../utils/lock-scroll';

type IndexProps = {
  data: any,
  location: any,
  ui: UiStore,
  json: JsonStore
}

@inject(STORE_UI, STORE_JSON)
@observer
class IndexPage extends React.Component<IndexProps, any> {

  //stores: any;
  //initPosition: boolean = false;
  //hammerTime: any;
  //ref: any;

  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    toggleLock(true);
  }

  componentWillUnmount() {
    toggleLock(false);
  }


  render() {

    return (
<iframe seamless sandbox="allow-forms allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups"
            src='https://connect-embed.aproperhigh.com/find/?uuid=25f62eb4-1168-4de7-9322-2300385bb1a2&utm_campaign=pulse-clicks&utm_source=pure-beauty&embed=1'
            style={{
              width: '100%', height: '100%', border: 'none', position: 'absolute', top: 90, left: 0, right: 0, bottom: 0
            }} />      
    )
  }
}


export default IndexPage;
